<template>
    <div>
        <v-toolbar color="primary" dark dense>
            <v-toolbar-title>Templates Filter</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.stop="$emit('close')">
                <v-icon small>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card tile>
            <v-card-text>
                <v-select
                    v-model="filterData.type"
                    clearable
                    color="primary"
                    solo
                    :items="types"
                    item-text="text"
                    item-value="value"
                    hint="Filter by template type"
                    persistent-hint
                    placeholder="Type"
                ></v-select>
                <v-select
                    v-model="filterData.communication_type"
                    clearable
                    color="primary"
                    solo
                    :items="communication_types"
                    item-text="text"
                    item-value="value"
                    hint="Filter by communication type"
                    persistent-hint
                    placeholder="Communication Type"
                ></v-select>
                <v-select
                    v-model="filterData.language"
                    clearable
                    color="primary"
                    solo
                    :items="languages"
                    item-text="text"
                    item-value="value"
                    hint="Filter by language"
                    persistent-hint
                    placeholder="Language"
                ></v-select>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click.stop="applyFilter">
                    <v-icon class="mr-2">mdi-check-circle</v-icon>Apply
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>
<script>

export default {
    name: "filter-dialog",
    components: {},
    props: {
        filter: {
            type: Object,
            required: false,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            types: [
                {
                    text: "Applicant",
                    value: "Applicant",
                },
                {
                    text: "Landlord",
                    value: "Landlord",
                },
                {
                    text: "Employer",
                    value: "Employer",
                },
            ],
            communication_types: [
                {
                    text: "Fax",
                    value: "fax",
                },
                {
                    text: "SMS",
                    value: "sms",
                },
                {
                    text: "Email",
                    value: "email",
                },
            ],
            languages: [
                {
                    text: "English",
                    value: "en",
                },
                {
                    text: "Spanish",
                    value: "es",
                },
            ],
            filterData: { ...this.filter },
        };
    },
    methods: {
        applyFilter() {
            // TODO: fix error when field is cleared
            this.$emit("filter-templates", this.filterData);
            this.$emit("close");
        },
    },
};
</script>

<style scoped>
</style>