var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-toolbar",
        { attrs: { color: "primary", dark: "", dense: "" } },
        [
          _c("v-toolbar-title", [_vm._v("Templates Filter")]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.$emit("close")
                }
              }
            },
            [_c("v-icon", { attrs: { small: "" } }, [_vm._v("mdi-close")])],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { attrs: { tile: "" } },
        [
          _c(
            "v-card-text",
            [
              _c("v-select", {
                attrs: {
                  clearable: "",
                  color: "primary",
                  solo: "",
                  items: _vm.types,
                  "item-text": "text",
                  "item-value": "value",
                  hint: "Filter by template type",
                  "persistent-hint": "",
                  placeholder: "Type"
                },
                model: {
                  value: _vm.filterData.type,
                  callback: function($$v) {
                    _vm.$set(_vm.filterData, "type", $$v)
                  },
                  expression: "filterData.type"
                }
              }),
              _c("v-select", {
                attrs: {
                  clearable: "",
                  color: "primary",
                  solo: "",
                  items: _vm.communication_types,
                  "item-text": "text",
                  "item-value": "value",
                  hint: "Filter by communication type",
                  "persistent-hint": "",
                  placeholder: "Communication Type"
                },
                model: {
                  value: _vm.filterData.communication_type,
                  callback: function($$v) {
                    _vm.$set(_vm.filterData, "communication_type", $$v)
                  },
                  expression: "filterData.communication_type"
                }
              }),
              _c("v-select", {
                attrs: {
                  clearable: "",
                  color: "primary",
                  solo: "",
                  items: _vm.languages,
                  "item-text": "text",
                  "item-value": "value",
                  hint: "Filter by language",
                  "persistent-hint": "",
                  placeholder: "Language"
                },
                model: {
                  value: _vm.filterData.language,
                  callback: function($$v) {
                    _vm.$set(_vm.filterData, "language", $$v)
                  },
                  expression: "filterData.language"
                }
              })
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.applyFilter($event)
                    }
                  }
                },
                [
                  _c("v-icon", { staticClass: "mr-2" }, [
                    _vm._v("mdi-check-circle")
                  ]),
                  _vm._v("Apply ")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }